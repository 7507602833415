import React, {useEffect, useRef, useState} from 'react'
import Hero from './Hero'
import './style.scss'
import Video from '../../components/Video'
import works from './works.json'
import TextOpacity from '../../components/TextOpacity'
import {motion} from 'framer-motion'

function Home() {
  const width = useRef(null)
  const [vidHeight, setVidHeight] = useState({big:360, small:100})

  useEffect(()=>{
    const vidWidth = 0.58 * width?.current.offsetWidth
    const vidWidthSmall = width?.current.offsetWidth
    setVidHeight({big:(4 * vidWidth)/7, small:(4 * vidWidthSmall)/7})
  },[])

  return (
    <div className='home'>
        <Hero/>

        <div className="works" ref={width}>
          <div className="title-con">
            <h3 className='title'>
              <TextOpacity
                defaultValue="Work"
                duration={0.1}
              />
             </h3>
            <motion.div className="line"
              initial={{width:"0"}}
              whileInView={{width:"100%"}}
              viewport={{once:true}}
              transition={{
                duration:1,
                ease:[0.51, 0.12, 1, 0.15],
              }}
            ></motion.div>
          </div>
          {
            works.map((item, index)=>(
              <Video
                title={item.title}
                desc={item.description}
                vid={item.video}
                index={index}
                height={vidHeight}
              />
            ))
          }
        </div>
    </div>
  )
}

export default Home