import React from 'react'
import Particle from '../../components/Particle'
import {motion} from 'framer-motion'

function Hero() {

  return (
    <div className='container hero'>
        <Particle id="tsparticles" />

        <motion.svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className='arrow'
          initial={{y:0, x:"-50%"}}
          animate={{
            y:5,
            transition:{
              repeatType: "mirror",
              repeat:Infinity,
              duration:0.5
            }
          }}
        >
          <path d="M20.7529 7.51953V30.8529" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M32.4196 19.1862L20.753 30.8528L9.0863 19.1862" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </motion.svg>

    </div>
  )
}

export default Hero