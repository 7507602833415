import React from 'react'

function Footer() {
  return (
    <div id='footer'>
        <div className="content">
            <div className="address">
                <section>
                    <h5>Contact  Me</h5>
                </section>
                
                <section>
                    <tr><a href="mailto: WoolcottEdit@Gmail.com">
                        <td className='social'><img src="assets/mail.png" alt="" /><p>
                            WoolcottEdit@Gmail.com
                        </p></td></a>
                    </tr>

                    <tr><a href="Telp: +1 908-477-5794">
                        <td className='social'><img src="assets/phone.png" alt="" /><p>+1 908-477-5794</p></td>
                        </a>
                    </tr>

                    <tr><a href="https://www.linkedin.com/in/ruben-woolcott/">
                        <td className='social'><img src="assets/linkedin.png" alt="" /><p>ruben-woolcott</p></td>
                        </a>
                    </tr>
                </section>
                
            </div>
        </div>
        <div className="copyright">
            <p>© Copyright 2022 - Ruben Woolcott, All rights reserved</p>
        </div>
    </div>
  )
}

export default Footer