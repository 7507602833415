import React from 'react'
import {useSetVideo} from '../VidContext'

function Play({vid}) {
  const setVideo = useSetVideo()

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className='play-btn' onClick={()=>setVideo(vid)}>
    <path d="M16.1667 12.5L27.8334 20L16.1667 27.5V12.5Z" fill="#FCA311" stroke="#FCA311" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#FCA311"/>
    </svg>
  )
}

export default Play