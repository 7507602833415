import React from "react";
import Home from "./pages/home/Home";
import Footer from "./components/Footer";
import About from "./pages/about/About";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Nav from "./components/Nav";
import {useViewportScroll} from 'framer-motion'
import SmoothScroll from "./components/SmoothScroll";
import OverlayVid from "./components/OverlayVid";
import {useVideo} from './VidContext'

function App() {
  const { scrollY } = useViewportScroll();
  const video = useVideo()

  return (
    <div className="App">
      <Router>
      
        <OverlayVid url={video}/>
      
        <Switch>

          <Route exact path="/about">
            <Nav scrollY={scrollY} animate={false}/>
            <SmoothScroll scrollY={scrollY}>
              <About/>
              <Footer/>
            </SmoothScroll>
          </Route>

          <Route exact path="/">
            <Nav scrollY={scrollY} animate={true}/>
            <SmoothScroll scrollY={scrollY}>
              <Home/>
              <Footer/>
            </SmoothScroll>
          </Route>

        </Switch>
      </Router>

      

    </div>
  );
}

export default App;
