import React, {useEffect, useState, useRef} from 'react'
import {motion, useTransform} from 'framer-motion'
import TextOpacity from './TextOpacity';

const sidebar = {
    open:{
      clipPath: `circle(${500*2 + 200}px at 0px 0px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    },
    closed: {
      clipPath: "circle(0px at 0px 0px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };

function Nav({scrollY, animate}) {
  const ref = useRef(null);
  const [height, setHeight]=useState(50*window.innerHeight/100);
  const [isOpen, toggleOpen] = useState(false);
  const backY = useTransform(scrollY, [1, height], [height, 0]);
  const scale = useTransform(scrollY, [1, height], [3, 1]);
  const opacity = useTransform(scrollY, [1, height], [1,0]);
  const opacityBag = useTransform(
    scrollY,
    [1, height],
    ["rgba(0,0,0,0)","#14213D"]
)
  
  useEffect(()=>{
    setHeight(height - ref.current.clientHeight/2)
  },[])

  return (
    <motion.div className='header'
    initial={false}
    animate={isOpen ? "open" : "closed"}
    style={animate&&{ background:opacityBag }}
    custom="100%"
    >   
      <nav>
        {
            isOpen?
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={()=>toggleOpen(false)}>
                <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            :
            <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>toggleOpen(true)}>
              <path d="M25.417 13.8534C25.417 14.4573 24.9271 14.9471 24.3233 14.9471H1.09369C0.489883 14.9471 0 14.4573 0 13.8534C0 13.2496 0.489883 12.7598 1.09369 12.7598H24.3233C24.9271 12.7598 25.417 13.2496 25.417 13.8534ZM9.74116 25.5195H1.09411C0.4903 25.5195 0.000417052 26.0094 0.000417052 26.6132C0.000417052 27.217 0.4903 27.7069 1.09411 27.7069H9.74116C10.345 27.7069 10.8349 27.217 10.8349 26.6132C10.8349 26.0094 10.345 25.5195 9.74116 25.5195ZM38.9063 0H1.09411C0.4903 0 0.000417052 0.489883 0.000417052 1.09369C0.000417052 1.6975 0.4903 2.18739 1.09411 2.18739H38.9063C39.5101 2.18739 40 1.6975 40 1.09369C40 0.489883 39.5101 0 38.9063 0Z" fill={isOpen?"black":"white"}/>
            </svg>
        }
      </nav>

      <motion.div className="text"
        ref={ref}
        initial={animate&&{ y: 100, scale: 2 }}
        animate={animate&&{
          transition: {
            duration: 3
          }
        }}
        style={animate&&{ y: backY, scale: scale }}
      >
        <h1>
          <TextOpacity
            defaultValue="Ruben Woolcott"
            duration={0.1}
          />
      </h1>
      <motion.h2
          initial={animate?{opacity:1}:{opacity:0}}
          style={animate&&{opacity:opacity}}
        >
          <TextOpacity
            defaultValue="Video Editor"
            duration={0.1}
          />
        </motion.h2>
      </motion.div>
        
        <motion.div className="navbar" variants={sidebar} >
            <ul>
                <li>
                    <a href="/">
                        Home
                    </a>
                </li>
                <li>
                    <a href="/about">
                        About
                    </a>
                </li>
            </ul>
        </motion.div>
    </motion.div>
  )
}

export default Nav