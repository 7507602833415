import {motion} from 'framer-motion'
import TextSlideUp from '../../components/TextSlideUp'

function About() {
    const container = {
        hidden: { opacity: 0 },
        show: {
          opacity: 1,
          transition: {
            staggerChildren: 0.5,
          }
        }
      }

      const item = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
      }
      
      
  return (
    <div className='container home about'>
        <section>
            <span className='span-title'>
                <h1>
                    <TextSlideUp>
                        About Me
                    </TextSlideUp>
                </h1>
            </span>
            <p>
                <TextSlideUp>
                I am a highly experienced editor/ producer. Using either Premiere or Avid, remotely or in the office, I am always an indispensable asset on any team. I feel strongly that editing goes beyond just operating the software and am always keen to help craft the story. Throw me a script and some footage though, and I’m equally happy left to my own devices to realize your vision. I am most often known as unflappable, easy to work with, and very fast.
                </TextSlideUp>
            </p>
            <br/>
            <p>
                <TextSlideUp>
                I started my career as an assistant editor, overnight, where I quickly realized story based editing is where my strengths lay. I have worked on a variety of projects from reality TV to multiple-hour long documentaries. Most recently the bulk of my work has been relaunching Forensic Files as Forensic Files II. Working with some of the original show’s team and helping to integrate with the in-house CNN/ HLN team we were able to launch a successful new chapter in this mainstay of the true crime genre. While I am proud of my achievements thus far, I’m always looking to apply my skills to the next challenge, whatever genre the story might be.
                </TextSlideUp>
            </p>
        </section>
        <section>
            <span className='span-title'>
               <h3>
                    <TextSlideUp>
                        Contact Me
                    </TextSlideUp>
                </h3> 
            </span>
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"  
            >
            <motion.tr
                variants={item} 
            >   
            <a href = "mailto: WoolcottEdit@Gmail.com">
                <td className='social'><img src="assets/mail-white.png" alt="" /> <p>WoolcottEdit@Gmail.com</p></td>
            </a></motion.tr>
            <motion.tr variants={item} >
                <a href="Tel: +1 908-477-5794">
                <td className='social'><img src="assets/phonewhite.png" alt="" /><p>+1 908-477-5794</p></td>
            </a></motion.tr>
            <motion.tr variants={item} >
            <a href="https://www.linkedin.com/in/ruben-woolcott/ ">
                <td className='social'><img src="assets/linkedin-white.png" alt="" /><p>ruben-woolcott</p></td>
            </a></motion.tr>
            </motion.div>
        </section>
    </div>
  )
}

export default About