import React, {useEffect, useState} from 'react'
import {motion} from 'framer-motion'
import ReactPlayer from 'react-player'
import Play from './Play'
import TextSlideUp from './TextSlideUp'

const Left = ({vid, desc, title, height}) =>{
    return(
        <>
        <section className='sec-vid'>
            <span>
                <motion.div className="line"
                  initial={{width:"0"}}
                  whileInView={{width:"100%"}}
                  viewport={{once:true}}
                  transition={{
                    duration:1,
                    ease:[0.51, 0.12, 1, 0.15],
                  }}
                ></motion.div>
            </span>
            <motion.div
                initial={{x:'-300px'}}
                whileInView={{x:0}}
                transition={{
                    duration:1,
                }}
                viewport={{once:true}}
                style={{position:"relative"}}
            >
                <Play
                    vid={vid}
                />
                <ReactPlayer url={vid} width="100%" height={`${height}px`}/>
            </motion.div>
        </section>
        <section className='sec-title'>
            <span className='title-span'>
                <h4>
                {/* <TextOpacity
                  defaultValue={title}
                  duration={0.01}
                /> */}
                <TextSlideUp>
                    {title}
                </TextSlideUp>
                </h4>
            </span>

            <p>
                <TextSlideUp>
                    {desc}
                </TextSlideUp>
            </p>
        </section>
        </>
    )
}

const Right = ({vid, desc, title, height}) =>{
    return(
        <>
        <section className='sec-title'>
            <span className='title-span'>
                <h4>
                <TextSlideUp>
                    {title}
                </TextSlideUp>
                </h4>
            </span>

            <p>
                <TextSlideUp>
                    {desc}
                </TextSlideUp>
            </p>
        </section>
        <section className='sec-vid'>
            <span>
                <motion.div className="line"
                  initial={{width:"0"}}
                  whileInView={{width:"100%"}}
                  viewport={{once:true}}
                  transition={{
                    duration:1,
                    ease:[0.51, 0.12, 1, 0.15],
                  }}
                ></motion.div>
            </span>
            <motion.div
                initial={{x:'-300px'}}
                whileInView={{x:0}}
                transition={{
                    duration:1,
                }}
                viewport={{once:true}}
                style={{position:"relative"}}
            >
                <Play
                    vid={vid}
                />
                <ReactPlayer url={vid} width="100%" height={`${height}px`}/>
            </motion.div>
        </section>
        </>
    )
}

function Video({title, desc, vid, index, height}) {
    const [matches, setMatches] = useState(window.matchMedia("(max-width: 600px)").matches)

    useEffect(() => {
        window.matchMedia("(max-width: 600px)").addEventListener('change', e => setMatches(e.matches));
    }, []);


  return (
    <div className='video'>
        {
            matches?<Left vid={vid} desc={desc} title={title} height={matches?height.small:height.big}/>:index % 2 === 0?<Left vid={vid} desc={desc} title={title} height={matches?height.small:height.big}/>:<Right  vid={vid} desc={desc} title={title} height={matches?height.small:height.big}/>
        }
    </div>
  )
}

export default Video