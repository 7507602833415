import React, {useContext, useState} from 'react'

const SelectedCategory = React.createContext()
const SetSelectedCategory = React.createContext()

export function useSetVideo(){
    return useContext(SetSelectedCategory)
}

export function useVideo(){
    return useContext(SelectedCategory)
}

export function VideoProvider({children}){
    const [selectedCategory, setSelectedCategory] = useState("")

    function setSelected(category){
        setSelectedCategory(category)
    }

    return(
        <SelectedCategory.Provider value={selectedCategory}>
            <SetSelectedCategory.Provider value={setSelected}>
                {children}
            </SetSelectedCategory.Provider>
        </SelectedCategory.Provider>
    )
}