import React from 'react'
import ReactPlayer from 'react-player'
import {useSetVideo, useVideo} from '../VidContext'
import {motion, AnimatePresence} from 'framer-motion'

function OverlayVid({url}) {
  const setVideo = useSetVideo()
  const video = useVideo()

  return (
    <AnimatePresence>
      {
        video!==""&&(
          <motion.div className='overlay'
            initial={{ x: -2000 }}
            animate={{ x: 0 }}
            exit={{ x: -2000 }}
            transition={{
              duration:0.5
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>setVideo("")}>
                <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div className="player-full">
            <ReactPlayer
              url={url}
              width="100%"
              height="100%"
              controls
              playing={true}
            />
            </div>
          </motion.div>
        )
      }
    </AnimatePresence>
  )
}

export default OverlayVid